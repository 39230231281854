export function initializeStickyHorizontalScroll(rowSelector) {
    const row = document.querySelector(rowSelector);
    const rowParent = row.parentNode;
    // let observer = false;
    // let observer1 = false;
    const spacer = document.querySelector(".spacer");
    const lineWr = document.querySelector(".line-wr");
    let trigger = document.querySelector(".trigger");
    let winHeight;
    let rootMargin_;
    let isSticky = false;
    let isInView = false;
    let rowOfset;
    window.addEventListener("scroll", () => { 
        rowOfset = row.parentNode.parentNode.getBoundingClientRect().top + window.scrollY;
        

    });

    // Set spacer height based on row scroll width
    function setSpacerHeight() {
        //spacer.style.height = `${window.innerHeight}px`;
        spacer.style.height = `${row.scrollWidth}px`;
        winHeight = window.innerHeight;
        const rowHeight = row.offsetHeight;
        //rowOfset = row.parentNode.getBoundingClientRect().top;
        //let rM= ((winHeight - (rowHeight * 1)) * -1) + 'px';
        rootMargin_ = '0px 0px 0px 0px';
        
        trigger.style.top = (rowHeight * -1)+'px';
        
    }
    // Preload images and run callback after all images are loaded
    function preloadImages(selector, callback) {
        const images = document.querySelectorAll(selector + " img");
        let imagesLoaded = 0;

        images.forEach((img) => {
            const src = img.getAttribute("src");
            if (src) {
                const preloadedImage = new Image();
                preloadedImage.src = src;
                preloadedImage.onload = () => {
                    imagesLoaded += 1;
                    if (imagesLoaded === images.length && typeof callback === "function") {
                        callback();
                    }
                };
            }
        });
    }

    // Handle horizontal scroll based on vertical page scroll
    function handleScroll() {
        
        if (!isSticky) {
            console.log('sticky false....');
            
            return; 
            
        } // Only scroll horizontally when sticky
      
        const rowMaxScrollLeft = row.scrollWidth - row.clientWidth;
      
        const per = rowMaxScrollLeft / (spacer.offsetHeight);
       
        const scroll = (window.scrollY - rowOfset);
       
        row.scrollLeft = (scroll * per);
    
       
        
    }
    
    // IntersectionObserver to trigger when row reaches the top of the viewport
    
    let observer = new IntersectionObserver((entries) => {
        
        if (!observer) return;  
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                
                
                isSticky = true;
                window.addEventListener("scroll", handleScroll);
            } else {
                isSticky = false;
                //lineWr.style.position = 'absolute';
                
                //lineWr.style.position = 'absolute';
                //row.scrollLeft = 0; // Reset scroll if not sticky
                window.removeEventListener("scroll", handleScroll);
                //alert('remo');
            }
        });
    }, {
        threshold: 0,
        rootMargin: '0px 0px -100% 0px',
    });
    observer.observe(row);

    // Create the intersection observer
    let observer1 = new IntersectionObserver((entries) => {
        if (!observer1) return;
        entries.forEach(entry => {
            if (entry.isIntersecting && !isInView) {
                // Trigger only when leaving the screen at the top
                
                lineWr.classList.add('fadeOut'); // or add/remove class based on your need
                isInView = true; // Update the flag to indicate it's out of view
                
            } else if (entry.isIntersecting && isInView) {
                // Reset flag when the element re-enters the viewport
                
                isInView = false;
                
                lineWr.classList.remove('fadeOut');
            }
        });
    }, {
        root: null,             // Use the viewport as the root
        rootMargin: '0px 0px -100% 0px',  // Trigger when 1 pixel leaves the screen at the top
        threshold: 0            // Trigger as soon as the element starts leaving
    });

    // Start observing the target element
    
    observer1.observe(trigger);

    var so = setTimeout(() => { 
        
    },0);
    

    // Initialize image preloading with callback to set spacer height and add resize listener
    preloadImages(rowSelector, () => {
        setSpacerHeight();
        window.addEventListener("resize", setSpacerHeight); // Adjust spacer height on window resize
    });
}

// Initialize with your selector
//initializeStickyHorizontalScroll(".scroll-item-wr");